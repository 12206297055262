import Box from "@mui/material/Box";
import Overline from "src/components/Overline";
import Paragraph from "src/components/Paragraph";
import { Link as RawLink } from "gatsby";
import { Link } from "gatsby-theme-material-ui";
import posts from "posts";
import * as React from "react";
import SubHeader from "src/components/SubHeader";

export function LargePost({ post }) {
  const to = "/community-stories/" + post.slug;

  return (
    <Box
      display="flex"
      sx={{
        mb: 4,
        textDecoration: "none",
        flexDirection: { xs: "column-reverse", sm: "row" },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <SubHeader
          component={Link}
          to={to}
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            display: "block",
          }}
        >
          {post.title}
        </SubHeader>
        <Paragraph
          variant={{ xs: "body2", sm: "body1" }}
          component="div"
          sx={{ textDecoration: "none" }}
          color="textPrimary"
        >
          {post.blurb}
        </Paragraph>
        <Paragraph variant="body2" component={Link} to={to}>
          Read More
        </Paragraph>
      </Box>
      {(post.thumb || post.img) && (
        <Box>
          <RawLink to={to}>
            <Box
              sx={{
                backgroundImage: {
                  xs: `url("${post.img || post.thumb}")`,
                  sm: `url("${post.thumb || post.img}")`,
                },
                backgroundSize: "cover",
                backgroundPosition: post.imgBackgroundPosition,
                height: { xs: "50vw", sm: 150 },
                width: { xs: "100%", sm: 150 },
                ml: { sm: 2 },
                mb: 2,
              }}
            />
          </RawLink>
        </Box>
      )}
    </Box>
  );
}

export function SmallPost({ post }) {
  const to = "/community-stories/" + post.slug;

  return (
    <Box
      display="flex"
      sx={{
        mb: 4,
        textDecoration: "none",
        // flexDirection: { xs: "column-reverse", sm: "row" },
      }}
    >
      <Box flexGrow={1}>
        <Overline
          component={Link}
          to={to}
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            mb: 1,
          }}
        >
          {post.title}
        </Overline>
        <Paragraph
          variant="body2"
          component="div"
          sx={{ textDecoration: "none" }}
          color="textPrimary"
        >
          {post.blurb}
        </Paragraph>
      </Box>

      <Box>
        {(post.thumb || post.img) && (
          <RawLink to={to}>
            <Box
              component="img"
              src={post.thumb || post.img}
              sx={{
                objectFit: "cover",
                objectPosition: post.imgBackgroundPosition,
                height: 100,
                width: 100,
                ml: 2,
                mb: 2,
              }}
            />
          </RawLink>
        )}
      </Box>
    </Box>
  );
}

export default function CommunityStoriesList() {
  return (
    <Box
      pt={4}
      sx={{
        columnCount: { md: 2 },
        columnGap: { md: 8 },
      }}
    >
      {posts.slice(0, 7).map((post) => {
        return <SmallPost post={post} key={post.slug} />;
      })}
    </Box>
  );
}
