import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Color from "color";
import CommunityStoriesList from "src/components/CommunityStoriesList";
import SubHeader from "src/components/SubHeader";
import Overline from "src/components/Overline";
import { Button, Link } from "gatsby-theme-material-ui";
import * as React from "react";
import Container from "src/components/Container";
import ImageBlock from "src/components/ImageBlock";
import InvertBox from "src/components/InvertBox";
import Layout from "src/components/Layout";
import healingOurCommunity from "src/images/home/healing-our-community.jpg";
import homeBg from "src/images/home/home-bg.jpg";
import honoringPersonalStories from "src/images/home/honoring-personal-stories.jpg";
import leadingWithCompassion from "src/images/home/leading-with-compassion.jpg";
import maintainingOurAnchorInOakland from "src/images/home/maintaining-our-anchor-in-oakland.jpg";
import { BLOG_WIDTH } from "constants";

const VALUES = [
  {
    title: "Leading with Compassion",
    img: leadingWithCompassion,
    text: (
      <>
        <b>We lead with compassion,</b> and we’re focused on building trust and
        connection. Effective, dignified care begins with genuinely
        understanding a person as who they are, not just what happened to them.
      </>
    ),
  },
  {
    title: "Honoring Personal Stories",
    img: honoringPersonalStories,
    text: (
      <>
        <b>
          We listen and pay close attention to the unique stories of each of our
          clients,
        </b>{" "}
        as their experiences of homelessness are varied and complex.
      </>
    ),
  },
  {
    title: "Maintaining Our Anchor in Oakland",
    img: maintainingOurAnchorInOakland,
    text: (
      <>
        <b>
          We’ve stayed rooted in Oakland for 30+ years, with a steadfast
          commitment to our home neighborhood,
        </b>{" "}
        while also providing dedicated care to individuals and families across
        Alameda County.
      </>
    ),
  },
  {
    title: "Healing Our Community",
    img: healingOurCommunity,
    text: (
      <>
        <b>
          As a Black-led organization, we’re working to heal generations of
          racial and economic disparities through personalized support,
        </b>{" "}
        because we know that a “one size fits all” approach only perpetuates
        systemic racism and poverty.
      </>
    ),
  },
];
function Values() {
  return (
    <>
      {/* values blocks for mobile */}
      <Box sx={{ display: { sm: "none" } }} mt={5}>
        {VALUES.map(({ title, img, text }) => {
          return (
            <Box mb={5} key={title}>
              <ImageBlock title={title} img={img}>
                <Typography>{text}</Typography>
              </ImageBlock>
            </Box>
          );
        })}
      </Box>
      {/* values blocks for desktop */}
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <Container>
          <Grid container spacing={3}>
            {VALUES.map(({ title }) => {
              return (
                <Grid item sm={3} key={title}>
                  <SubHeader>{title}</SubHeader>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Container
          sx={{
            background: (theme) =>
              `linear-gradient(180deg, transparent 50%, ${theme.palette.green[50]} 50%)`,
          }}
        >
          <Grid container spacing={3}>
            {VALUES.map(({ title, img }) => {
              return (
                <Grid item sm={3} key={title}>
                  <Box
                    component="img"
                    alt={title}
                    src={img}
                    sx={{ width: "100%" }}
                    my={2}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Container
          sx={{
            backgroundColor: "green.50",
          }}
        >
          <Grid container spacing={3} pb={3}>
            {VALUES.map(({ title, text }) => {
              return (
                <Grid item sm={3} key={title}>
                  <Typography>{text}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
const IndexPage = () => {
  return (
    <Layout>
      <InvertBox
        sx={{
          backgroundImage: (theme) => `linear-gradient(
            ${Color(theme.palette.green[900]).alpha(0.7).string()}, 
            ${Color(theme.palette.green[900]).alpha(0.7).string()}
          ), url(${homeBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: { xs: 320, sm: "55vh", md: "70vh" },
          alignItems: "center",
          display: "flex",
        }}
        py={4}
      >
        <Container>
          <Typography
            variant="headline"
            component="h1"
            sx={{ color: "text.primary", maxWidth: "20em" }}
            pb={3}
          >
            East Oakland Community Project nurtures the dreams of people
            experiencing homelessness
          </Typography>

          <Button to="/donate" variant="contained" sx={{ mr: 1 }}>
            Donate
          </Button>
          <Button to="/what-we-do" variant="outlined">
            What We Do
          </Button>
        </Container>
      </InvertBox>
      <Values />
      <Container sx={{ py: 4, maxWidth: { md: "100%", xs: BLOG_WIDTH } }}>
        <Overline component={Link} to="/community-stories">
          Community Stories
        </Overline>
        <CommunityStoriesList />
      </Container>
    </Layout>
  );
};

export default IndexPage;
